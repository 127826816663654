.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.spinner {
  width: 64px;
  height: 64px;
  color: white;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
